.c-file-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 40px;

  &-field {
    display: none;
  }

  &-label,
  &-name {
    font-size: 12px;
  }

  &-label {
    margin: 0;
    text-transform: uppercase;
  }

  .c-button {
    margin: 0 0 5px;
  }

  &-error {
    p {
      color: red;
    }
  }
}
