.c-enable-two-factor {
  &-container {
    padding: 10px 40px 0 40px;
  }

  &-box {
    max-width: 1000px;
    padding: 20px;
    background: #f9c7c7;
    border-radius: $box-border-radius;

    &-title {
      font-size: 18px;
    }
  }
}
