.c-titles {
  width: 100%;
  max-width: 300px;
  margin: 0 0 40px;

  &.-other {
    .c-input {
      &-error {
        border-top: 0;
      }
    }
  }

  &-label {
    font-size: 11px;
    text-align: left;
  }

  &-list {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid #000;
    border-radius: 6px;

    &-container {
      position: relative;
    }

    &.-other-selected {
      margin: 0 0 10px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    padding: 0 10px;
    font-size: $font-size;
    font-weight: $input-font-weight;
    line-height: 1;
    background-color: #fff;
    border-right: 1px solid #000;
    transition: all 0.3s ease-in-out;

    &:last-child {
      border-right: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba($color-brand-primary, 0.5);
    }

    &.-selected {
      background-color: $color-brand-primary;
    }
  }

  .c-input-error {
    width: 100%;
    margin: 5px 0 0;
    border-top: 1px solid $color-error;
  }
}
