%a-input {
  min-height: $input-min-height;
  font-size: $font-size;
  font-weight: 400;
  background: $input-color-background;
  border: 0;
  border-bottom: 1px solid rgba($font-color, 0.6);
  border-radius: 4px 4px 0 0;
  transition: all 0.2ms ease-in-out;

  &-container {
    width: 100%;
    max-width: 360px;
    margin: 0 0 20px;
  }

  &:hover {
    background-color: lighten($input-color-background-focused, 6%);
  }

  &:focus {
    background-color: $input-color-background-focused;
    border-color: $color-brand-primary;
    border-bottom-width: 2px;
  }

  &.-error {
    border-color: rgba($color-error, 1);
  }
}
