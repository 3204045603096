.c-textarea {
  @extend %a-input-container;

  &-field {
    @extend %a-input;

    padding: $input-padding;
  }

  &-group {
    display: flex;
    flex-direction: column;
  }
}
