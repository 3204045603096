.c-fullscreen-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding-left: calc(#{$menu-width} + 20px);
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
