.c-task {
  // table component
  &-icon-wrap {
    @include transition-this(all, 0.1s, ease-in-out);

    display: inline-block;
  }

  &-pill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 90px;
    padding: 5px 16px;
    margin-bottom: 5px;
    font-weight: $fw-bold;
    color: #fff;
    background-color: #000;
    border-radius: 20px;

    &.-high {
      background-color: #f00;
    }

    &.-medium {
      background-color: #ffa500;
    }

    &.-low {
      background-color: #008000;
    }
  }

  &-table {
    position: relative;
    .c-admin-table-row {
      @include gpuAccelerate();
      @include transition-this(all, 0.1s, ease-in-out);

      padding: 20px;
      background-color: lighten(#808080, 45%);

      &:hover {
        background-color: #eee;
      }

      &.-selected {
        background-color: #fff;
      }

      &.-overdue {
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 100%;
          content: "";
          background-color: #f00;
        }
      }

      &.-completed {
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 100%;
          content: "";
          background-color: #008000;
        }
      }
    }

    .-pointer {
      cursor: pointer;
    }

    .-clickable {
      text-decoration: underline;
    }
  }
  // details component
  &-details {
    height: calc(100vh - 80px);
    padding: 20px;
    padding-top: 80px;

    &-label {
      font-size: 14px;
      color: #808080;
    }

    &-value {
      font-size: 14px;
      font-weight: 500;
    }
  }
  // text
  &-header {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  &-date {
    font-size: 14px;
  }

  &-priority {
    font-size: 12px;
  }

  &-status {
    font-size: 14px;
    color: #00f;
  }

  &-owner {
    font-size: 14px;
    color: #808080;
  }
}
