// Typography
// ========
$font-main: "work-sans", sans-serif;
$font-size: 14px;
$font-color: #2c2c3c;

// available font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;

// Global Colors
// ========
$color-body-background: #fff;
$color-brand-primary: #6edc9c;
$color-brand-secondary: #445;

// semantic colors
$color-error: #f56b6b;
$color-success: #6fe189;

// Easing
// ========
$ease-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// Global borders
// ========
$border-radius: 0;
$box-border-radius: 8px;

// Menu/Sidebar
// =========
$menu-color-bg: #0025bf;
$menu-color-text: #fff;
$menu-color-text-hover: #fff;
$menu-width: 120px;

// inputs
// ========
$input-color-background: #f5f5f5;
$input-color-background-focused: #dcdcdc;
$input-color-placeholder: rgb(5, 4, 4);
$input-radius: 6px;
$input-padding: 16px 30px 5px 10px;
$input-font-weight: $fw-light;
$input-min-height: 46px;
$input-color: #000;
$input-border-width: 1px;
$input-color-border: rgba($input-color, 0.6);
$input-border: $input-border-width solid $input-color-border;

// buttons
// ========
$button-padding: 14px 18px 14px;
$button-width: 100px;
$button-height: 40px;
$button-radius: 40px;
$button-bg: #6edc9c;
$button-color-text: $font-color;
$button-font-size: 14px;
$button-font-weight: $fw-bold;
$button-transform: none;
$button-shadow: none;

// Spacing
// ========
$spacing-base: 20px;

// Tables
// ========
$table-bg: #f7f7f7;

// Grid Breakpoints
// ========
$grid-breakpoints: (
  xxs: 0,
  xs: 340px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;
