.c-button {
  &.-next,
  &.-back {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 50%;

    &:hover {
      border-color: rgba(0, 0, 0, 1);

      .c-button-arrow {
        right: 10px;
        opacity: 1;

        &.-arrow-graphic {
          right: 0;
          fill: #fff;
          transform: rotate(-90deg) scale(1.5);

          path {
            fill: #fff;
          }
        }
      }
    }

    &.-left {
      transform: rotateZ(-90deg);
    }

    &.-right {
      transform: rotateZ(-90deg);
    }
  }

  &.-back {
    @include media-breakpoint-down("sm") {
      margin-bottom: 0;

      svg {
        top: calc(50% - 8px);
      }
    }
  }

  &.-next {
    margin-top: 10px;
    margin-bottom: 0;

    @include media-breakpoint-down("sm") {
      display: none;
    }

    .c-button-arrow {
      svg {
        top: calc(50% - 8px);
      }

      &.-arrow-graphic {
        transform: rotate(90deg);
      }
    }

    &:hover {
      .c-button-arrow {
        &.-arrow-graphic {
          transform: rotate(90deg) scale(1.5);
        }
      }
    }

    &.-mobile-next {
      @include border-radius(25px);

      display: none;
      width: 208px;
      color: $button-color-text !important;
      background-color: $button-bg !important;
      border-color: $button-bg !important;

      @include media-breakpoint-down("sm") {
        display: block;
      }

      svg {
        left: 150px;

        path {
          fill: $button-color-text !important;
        }
      }

      &:hover {
        svg {
          transform: rotate(90deg) scale(1.5) !important;
        }
      }
    }
  }
}
