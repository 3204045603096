.c-admin-panel {
  padding: 15px 20px;
  margin-top: 20px;
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  &.-success-box {
    background: #dcf7cc;
    border: 2px solid $color-success;
  }

  &.-error-box {
    background: #f9c7c7;
    border: 2px solid $color-error;
  }

  &.-info-box {
    background: #dcf8f9;
    border: 2px solid $color-brand-primary;
  }

  &-title {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 600;
    color: $color-brand-primary;

    &.-clickable {
      cursor: pointer;
    }
  }

  &-subtitle {
    font-size: 16px;

    svg {
      width: 25px;
      margin-right: 10px;
    }
  }

  &-close-box-icon {
    position: absolute;
    top: 25px;
    right: 20px;
    z-index: 2;
    width: 30px;
    height: 30px;
    text-align: center;
    background: transparent;
    border: none;
  }

  .password-box {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}
