.c-admin-menu {
  &-item {
    display: inline-block;
    cursor: pointer;

    &-link {
      color: $font-color;

      &:hover {
        color: $font-color;
        text-decoration: none;
      }
    }

    &-icon {
      font-size: 20px;
    }
  }
}
