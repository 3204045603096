.c-phone-call-container {
  @include transition-this(all, 0.3s, ease-in-out);

  position: fixed;
  right: 0;
  bottom: 0;
  max-height: 100%;
  font-size: 16px;
  text-align: center;
  background: transparent;

  &.-show {
    background: rgba(224, 224, 224, 0.25);

    .c-phone-call-box {
      width: 100%;
    }
  }

  .c-phone-call-box {
    @include transition-this(all, 0.3s, ease-in-out);

    width: 320px;
    max-height: 100%;
    padding: 30px 20px 20px;
    margin-bottom: 10px;
    background: #e8f3f7;
    border: 2px solid rgba(138, 138, 138, 0.1);
    border-radius: 6px;

    &.-extended {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &-container {
      position: relative;

      &-close-box {
        position: absolute;
        top: -18px;
        right: -3px;
        cursor: pointer;

        &-icon {
          font-size: 19px;
        }
      }

      .c-button {
        min-height: 20px;
        padding: 9px 10px;
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
}
