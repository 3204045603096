$max: 20;

.h-flip-icon-0 {
  transform: rotate(0deg);
}

.h-flip-icon-180 {
  transform: rotate(180deg);
}

.h-rotate-left {
  transform: rotateZ(-90deg) !important;
}

.h-rotate-right {
  transform: rotateZ(90deg) !important;
}

.h-rotate-180 {
  transform: rotateZ(180deg) !important;
}

.h-inline-block {
  display: inline-block;
}

.h-cursor-pointer {
  cursor: pointer;
}

.h-pointer-events-none {
  pointer-events: none;
}

.h-color-brand-primary {
  color: $color-brand-primary;
}

.h-color-brand-secondary {
  color: $color-brand-secondary;
}

.h-color-success {
  color: $color-success;
}

.h-color-text {
  color: $font-color;
}

.h-color-greyed {
  color: #000;
  opacity: 0.3;
}

.h-color-highlight {
  font-size: 20px;
  color: #000;
  transform-origin: 50% 50%;
}

.h-no-outline {
  &:focus {
    outline: 0;
  }
}

.h-disable-select {
  user-select: none;
}

.h-arrow-right {
  &::after {
    position: absolute;
    top: ($spacing-base * 4);
    right: $spacing-base;
    left: 0;
    width: 25px;
    height: 20px;
    content: "";
    background-repeat: no-repeat;
  }

  &.-arrow-sm-hidden {
    @include media-breakpoint-down("sm") {
      &::after {
        display: none;
      }
    }
  }

  &.-vert-align-center {
    @include translateY();

    top: 50%;
  }
}

@for $i from 0 through $max {
  .h-arrow-right {
    &.-arrow-t-#{$i} {
      &::after {
        top: calc($i * $spacing-base);
      }
    }
  }

  .h-mh-#{$i} {
    min-height: calc($i * $spacing-base) !important;
  }

  .h-Mh-#{$i} {
    max-height: calc($i * $spacing-base) !important;
  }

  .h-mw-#{$i} {
    min-width: calc($i * $spacing-base) !important;
  }

  .h-Mw-#{$i} {
    max-width: calc($i * $spacing-base) !important;
  }

  .h-m-#{$i} {
    margin: calc($i * $spacing-base) !important;
  }

  .h-mt-#{$i} {
    margin-top: calc($i * $spacing-base) !important;
  }

  .h-mr-#{$i} {
    margin-right: calc($i * $spacing-base) !important;
  }

  .h-mb-#{$i} {
    margin-bottom: calc($i * $spacing-base) !important;
  }

  .h-ml-#{$i} {
    margin-left: calc($i * $spacing-base) !important;
  }

  .h-p-#{$i} {
    padding: calc($i * $spacing-base) !important;
  }

  .h-pt-#{$i} {
    padding-top: calc($i * $spacing-base) !important;
  }

  .h-pr-#{$i} {
    padding-right: calc($i * $spacing-base) !important;
  }

  .h-pb-#{$i} {
    padding-bottom: calc($i * $spacing-base) !important;
  }

  .h-pl-#{$i} {
    padding-left: calc($i * $spacing-base) !important;
  }
}

@for $i from 0 through $max {
  @media screen and (max-width: 992px) {
    .h-mh-lg-#{$i} {
      min-height: calc($i * $spacing-base) !important;
    }

    .h-Mh-lg-#{$i} {
      max-height: calc($i * $spacing-base) !important;
    }

    .h-mw-lg-#{$i} {
      min-width: calc($i * $spacing-base) !important;
    }

    .h-Mw-lg-#{$i} {
      max-width: calc($i * $spacing-base) !important;
    }

    .h-m-lg-#{$i} {
      margin: calc($i * $spacing-base) !important;
    }

    .h-mt-lg-#{$i} {
      margin-top: calc($i * $spacing-base) !important;
    }

    .h-mr-lg-#{$i} {
      margin-right: calc($i * $spacing-base) !important;
    }

    .h-mb-lg-#{$i} {
      margin-bottom: calc($i * $spacing-base) !important;
    }

    .h-ml-lg-#{$i} {
      margin-left: calc($i * $spacing-base) !important;
    }
  }

  @media screen and (max-width: 992px) {
    .h-p-lg-#{$i} {
      padding: calc($i * $spacing-base) !important;
    }

    .h-pt-lg-#{$i} {
      padding-top: calc($i * $spacing-base) !important;
    }

    .h-pr-lg-#{$i} {
      padding-right: calc($i * $spacing-base) !important;
    }

    .h-pb-lg-#{$i} {
      padding-bottom: calc($i * $spacing-base) !important;
    }

    .h-pl-lg-#{$i} {
      padding-left: calc($i * $spacing-base) !important;
    }
  }
}

@for $i from 0 through $max {
  @media screen and (max-width: 768px) {
    .h-mh-md-#{$i} {
      min-height: calc($i * $spacing-base) !important;
    }

    .h-Mh-md-#{$i} {
      max-height: calc($i * $spacing-base) !important;
    }

    .h-mw-md-#{$i} {
      min-width: calc($i * $spacing-base) !important;
    }

    .h-Mw-md-#{$i} {
      max-width: calc($i * $spacing-base) !important;
    }

    .h-m-md-#{$i} {
      margin: calc($i * $spacing-base) !important;
    }

    .h-mt-md-#{$i} {
      margin-top: calc($i * $spacing-base) !important;
    }

    .h-mr-md-#{$i} {
      margin-right: calc($i * $spacing-base) !important;
    }

    .h-mb-md-#{$i} {
      margin-bottom: calc($i * $spacing-base) !important;
    }

    .h-ml-md-#{$i} {
      margin-left: calc($i * $spacing-base) !important;
    }
  }

  @media screen and (max-width: 768px) {
    .h-p-md-#{$i} {
      padding: calc($i * $spacing-base) !important;
    }

    .h-pt-md-#{$i} {
      padding-top: calc($i * $spacing-base) !important;
    }

    .h-pr-md-#{$i} {
      padding-right: calc($i * $spacing-base) !important;
    }

    .h-pb-md-#{$i} {
      padding-bottom: calc($i * $spacing-base) !important;
    }

    .h-pl-md-#{$i} {
      padding-left: calc($i * $spacing-base) !important;
    }
  }
}

@for $i from 0 through $max {
  @media screen and (max-width: 576px) {
    .h-mh-sm-#{$i} {
      min-height: calc($i * $spacing-base) !important;
    }

    .h-Mh-sm-#{$i} {
      max-height: calc($i * $spacing-base) !important;
    }

    .h-mw-sm-#{$i} {
      min-width: calc($i * $spacing-base) !important;
    }

    .h-Mw-sm-#{$i} {
      max-width: calc($i * $spacing-base) !important;
    }

    .h-m-sm-#{$i} {
      margin: calc($i * $spacing-base) !important;
    }

    .h-mt-sm-#{$i} {
      margin-top: calc($i * $spacing-base) !important;
    }

    .h-mr-sm-#{$i} {
      margin-right: calc($i * $spacing-base) !important;
    }

    .h-mb-sm-#{$i} {
      margin-bottom: calc($i * $spacing-base) !important;
    }

    .h-ml-sm-#{$i} {
      margin-left: calc($i * $spacing-base) !important;
    }
  }

  @media screen and (max-width: 576px) {
    .h-p-sm-#{$i} {
      padding: calc($i * $spacing-base) !important;
    }

    .h-pt-sm-#{$i} {
      padding-top: calc($i * $spacing-base) !important;
    }

    .h-pr-sm-#{$i} {
      padding-right: calc($i * $spacing-base) !important;
    }

    .h-pb-sm-#{$i} {
      padding-bottom: calc($i * $spacing-base) !important;
    }

    .h-pl-sm-#{$i} {
      padding-left: calc($i * $spacing-base) !important;
    }
  }
}
