.c-admin-paginator {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-radius: 0.25rem;

  &-item {
    &:first-child,
    &:last-child {
      display: none;
    }

    &-button {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: $font-color;
      background-color: #fff;
      border: 1px solid #dee2e6;
      outline: none;

      &:focus {
        outline: none;
      }
    }

    &.-active {
      .c-admin-paginator-item-button {
        z-index: 1;
        color: #000;
        background-color: $color-brand-primary;
        border-color: $color-brand-primary;
      }
    }

    &.-disabled {
      .c-admin-paginator-item-button {
        z-index: 1;
        color: #6c757d;
        pointer-events: none;
        cursor: auto;
      }
    }
  }
}
