// variables
$radius: 5px;

// styles
.c-radio-select-button {
  @extend %a-input-container;
  @include gpuAccelerate();
  @include transition-this(all, 0.3s, ease-in-out);
  // border-right: 0;
  padding: 10px;
  margin-left: -1px;
  cursor: pointer;
  // max-width: 200px;
  background: #000;
  border: 1px solid #000;
  outline-width: 0;

  &-wrap {
    display: flex;
    flex-direction: row;
  }

  &-container {
    .t-radio-select-button-error {
      margin-top: -20px;
    }
  }

  &:first-child {
    @include border-radius($radius);
    // border-right: 0;
    margin-left: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    @include border-radius($radius);

    border: 1px solid #000;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus {
    outline: 0;
  }

  &.-selected {
    color: #000;
    background-color: #000;
  }
}
