.c-two-factor {
  .c-admin-panel {
    &.-select-radio-section {
      max-width: 60%;
      margin: auto;
    }
  }

  .c-input {
    width: auto;

    .c-input-field {
      max-width: 130px;
    }

    .c-button {
      margin-left: 20px;
    }
  }

  a {
    .-re-send-sms {
      margin-top: 20px;
      text-decoration: underline;
    }
  }
}
