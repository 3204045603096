$class: ".c-phone-number-input";
$field-width: 100px;
$font-size: 14px;

#{$class} {
  .vue-country-select {
    display: block;
    float: left;
    width: $field-width;
    min-height: $input-min-height;
    padding: 0;
    font-size: $font-size;
    font-weight: 400;
    color: $input-color;
    background: $input-color-background;
    border: 0;
    border-bottom: $input-border;
    border-radius: 4px 4px 0 0;
    transition: all 0.2s ease-in-out;

    .dropdown {
      min-height: calc(#{$input-min-height} - 1px);
      padding-top: 10px;
      padding-bottom: 0;
      transition: all 0.2s ease-in-out;
    }

    .dropdown.open,
    .dropdown-list {
      background: $input-color-background;
    }

    .dropdown:hover,
    .dropdown-item.highlighted {
      background: $input-color-background-focused;
    }

    .country-code {
      font-size: $font-size;
      color: inherit;
    }
  }

  .c-input-field {
    display: block;
    width: calc(100% - #{$field-width});
  }

  .c-input-label {
    left: calc(11px + #{$field-width} + 10px);
  }
}
