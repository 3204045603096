.c-wysiwyg {
  margin-bottom: 20px;

  &-label {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: $color-brand-primary;
  }

  &-preview {
    padding: 15px 20px;
    margin-bottom: 20px;
    background: #fff;
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
}
