.c-statsbar {
  position: fixed;
  top: 0;
  left: 160px;
  z-index: 98;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 140px);
  max-width: 1000px;
  padding: 10px;
  overflow: hidden;
  background: $menu-color-bg;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 75%);

  &-text {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: $menu-color-text;

    &-number {
      margin-left: 5px;
      font-size: 28px;
    }
  }
}
