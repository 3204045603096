$c-accordion-padding: 16px 0;

.c-accordion {
  &-indicator {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;

    &::after,
    &::before {
      position: absolute;
      top: 9px;
      right: 0;
      left: 0;
      display: block;
      width: 20px;
      height: 2px;
      margin: auto;
      content: "";
      background: $font-color;
      border-radius: 4px;
      transition: all 200ms ease-in-out;
    }

    &::after {
      transform: rotateZ(-90deg);
    }
  }

  &-item {
    padding: $c-accordion-padding;
    cursor: auto;
    border-bottom: 0;

    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    &-content {
      padding-bottom: 20px;
    }

    &.-parent {
      .c-accordion-item-content {
        padding-bottom: 0;
      }
    }

    &-title {
      padding: $c-accordion-padding;
      margin: -14px 0 -10px;
      font-size: 20px;
      font-weight: $fw-bold;
      color: $color-brand-primary;
      cursor: pointer;
      transition: all 200ms ease-in-out;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }

      &.-parent {
        padding-right: 16px;
        padding-left: 16px;
        font-size: 22px;
      }
    }

    &.-active {
      // selectors made more specific to support nested accordions

      .c-accordion-item-title {
        .c-accordion-indicator::after {
          transform: rotateZ(0deg);
        }
      }

      &.-parent {
        padding-bottom: 0;
      }
    }

    // nested
    .c-accordion {
      padding-right: 16px;
      padding-left: 16px;

      .c-accordion-item {
        .c-accordion-item-title {
          padding: 10px 0;
          font-size: 16px;
        }

        &:last-child {
          border-bottom: 0;
        }

        &-content {
          padding-bottom: 0;
        }
      }

      .c-accordion-indicator {
        &::before,
        &::after {
          width: 15px;
        }
      }
    }
  }

  &.-padding {
    .c-accordion-item {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  &.-isa {
    .c-accordion-item.-active .c-accordion-item-title {
      color: $color-brand-primary;
    }
  }
}
