/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * work-sans:
 *   - http://typekit.com/eulas/00000000000000003b9b139f
 *   - http://typekit.com/eulas/00000000000000003b9b13a0
 *   - http://typekit.com/eulas/00000000000000003b9b13a1
 *   - http://typekit.com/eulas/00000000000000003b9b13a2
 *   - http://typekit.com/eulas/00000000000000003b9b13a3
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */

/* {"last_published":"2019-11-11 12:40:05 UTC"} */

@import url("https://p.typekit.net/p.css?s=1&k=tmj4fyt&ht=tk&f=37542.37543.37544.37545.37546&a=17063079&app=typekit&e=css");

@font-face {
  font-display: swap;
  font-family: "work-sans";
  font-style: normal;
  font-weight: 300;
  src: url("https://use.typekit.net/af/d53556/00000000000000003b9b139f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d53556/00000000000000003b9b139f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d53556/00000000000000003b9b139f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "work-sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://use.typekit.net/af/0f7d9c/00000000000000003b9b13a0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/0f7d9c/00000000000000003b9b13a0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/0f7d9c/00000000000000003b9b13a0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "work-sans";
  font-style: normal;
  font-weight: 500;
  src: url("https://use.typekit.net/af/e96d05/00000000000000003b9b13a1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e96d05/00000000000000003b9b13a1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e96d05/00000000000000003b9b13a1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "work-sans";
  font-style: normal;
  font-weight: 700;
  src: url("https://use.typekit.net/af/b13a2b/00000000000000003b9b13a3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b13a2b/00000000000000003b9b13a3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b13a2b/00000000000000003b9b13a3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
}

.tk-work-sans {
  font-family: "work-sans", sans-serif;
}
