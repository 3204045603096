$class: ".c-switch";
$in-active-color: rgba($color-brand-primary, 0.5);
$active-color: $color-brand-primary;

#{$class} {
  position: relative;
  width: 40px;
  height: 24px;
  background-color: #fff;
  border: 1px solid $in-active-color;
  border-radius: 100px;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
  }

  &.-active {
    background-color: $active-color;
    border-color: $active-color;

    #{$class} {
      &-ball {
        left: 15px;
        border-color: $active-color;
      }
    }
  }

  &-ball {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 1px solid $in-active-color;
    border-radius: 100px;
    transition: all 0.3s ease;
  }
}
