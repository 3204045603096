.c-pcf {
  // max-width: 600px;
  // margin: 0 auto;
  &-input {
    width: 100%;

    @include media-breakpoint-down("sm") {
      width: 100%;
      margin: 0 auto;

      .c-input {
        width: 100%;

        &-group {
          justify-content: flex-start !important;

          label {
            padding: 5px 20px 12px 0 !important;
          }
        }

        &-field {
          padding-left: 0 !important;
          text-align: left !important;
        }
      }
    }

    .c-input {
      width: 100%;
      margin: 0;

      .c-input-error {
        top: 4rem;
        bottom: initial;
      }
    }
  }

  &-button {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 240px;
    @include media-breakpoint-down("md") {
      justify-content: flex-start;
      margin: 0 auto;
      margin-top: 10px;
    }
    @include media-breakpoint-down("sm") {
      justify-content: flex-start;
      margin: 0 auto;
      margin-top: 10px;

      button {
        width: 100%;
        min-height: 40px;
        padding: 12px 10px;

        &.-small {
          min-width: auto;
        }
      }
    }
  }

  &-error {
    padding: 0.5em;
    margin: 0 0 0.5em;
    font-size: 13px;
    color: #fff;
    background-color: $color-error;
    border-radius: 5px;
  }

  &-select {
    // max-width: 400px;
  }

  &-address-input {
    // max-width: 400px;
    width: 100%;
    @include media-breakpoint-down("sm") {
      margin: 0 auto;
    }
  }

  &-manual-address {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    @include media-breakpoint-down("sm") {
      font-size: 22px;
    }
  }
}
