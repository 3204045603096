.c-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: $menu-width;
  padding: 10px;
  overflow: hidden;
  text-align: center;
  background: $menu-color-bg;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 75%);

  &-logo {
    max-width: 100px;
    margin: 23px auto;
    cursor: pointer;
  }

  &-center {
    position: absolute;
    top: calc(50% - 25px);
  }

  &-start {
    width: 160%;
    padding-right: 60%;
    margin-top: 20px;
    margin-left: 60%;
    overflow: auto;
    color: #fff;
  }

  &-queue-details {
    position: relative;
    margin-bottom: 6px;
    font-size: 12px;

    &-size {
      display: block;
      font-size: 40px;
      font-weight: bold;
    }

    .c-accordion-item {
      padding: 0;
    }

    .c-accordion-item-title {
      padding: 0;
      margin: 5px 0;
      font-size: 11px;
      font-weight: $fw-regular;
      color: #fff;

      .row {
        margin: 0;
      }
    }

    &-box {
      position: absolute;
      width: 100%;
      padding: 4px 2px;
      font-size: 11px;
      color: #fff;
      background-image: linear-gradient(
        135deg,
        #1a1a1a 33.33%,
        #262626 0,
        #262626 50%,
        #1a1a1a 0,
        #1a1a1a 83.33%,
        #262626 0,
        #262626
      );
      border: solid 1px #fff;
    }
  }

  &-am-status {
    .c-select {
      &-input-label {
        font-size: 12px;
      }

      &-input-field {
        min-height: 25px;
        padding: 0 25px 0 10px;
        font-size: 12px;
      }

      &-input-arrow {
        width: 25px;
        height: 25px;
      }
    }

    .c-button {
      min-height: 25px;
      padding: 2%;
      font-size: 12px;
    }
  }

  &-end {
    width: 100%;
    min-height: 86px;
    background: rgba(#313131, 0.03);

    .-arrow-nav {
      margin: 10px 0;
    }

    .c-sidebar-item {
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &-menu-button {
    border-bottom: 1px solid rgba(255, 255, 255, 0.231373);
  }

  .-back {
    svg {
      transform: rotate(-90deg) scale(1.5);
    }
  }
}
