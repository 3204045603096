.c-section {
  padding: 40px;

  &.-half-vertical-padding {
    padding: 30px 60px;
  }

  &.-third-vertical-padding {
    padding: $spacing-base ($spacing-base * 3);
  }
}
