$class: ".c-info-expander";

#{$class} {
  h3 {
    font-weight: 400;
  }

  &-button {
    min-width: 135px;
    font-size: 14px;
    color: #00f;
  }

  &-content {
    @include gpuAccelerate();
    @include transition-this(all, 0.2s, ease-in-out);

    max-height: 0;
    overflow: hidden;
  }

  svg {
    @include gpuAccelerate();
    @include transition-this(all, 0.2s, ease-in-out);

    transform: rotate(0deg);

    path {
      fill: #00f;
    }
  }

  .-pointer {
    cursor: pointer;
  }

  .expand-element {
    max-height: 1500px;
  }

  .rotate-svg {
    svg {
      transform: rotate(180deg);
    }
  }
}
