.c-ar {
  padding: 10px;
  margin: 0 0 20px;
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);

  &-container {
    font-size: 14px;
  }

  &-or {
    text-align: center;
  }

  .c-button {
    &.-small {
      min-width: 75px;
      min-height: 30px;
      padding: 5px 20px;
      font-size: 14px;
    }
  }
}
