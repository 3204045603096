.c-select-input {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 360px;

  &.-reminder-option {
    width: 150px;
  }

  &-group {
    position: relative;
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 6px;
  }

  &-field {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 50px;
    padding: 0 60px 0 10px;
    background: none;
    border: 0;
    outline: none;
    appearance: none;

    &.-reminder-option {
      font-size: 14px;
    }
  }

  &-arrow {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-left: 1px solid #707070;

    img,
    svg {
      width: 16px;
      transform: rotate(90deg);
    }

    svg {
      fill: #2c2c31;
    }
  }

  &.-centered {
    margin: 0 auto;
  }
}
