.image {
  &-calendar {
    width: 30px;
    margin-top: 12px;
  }
  &-green-tick {
    position: absolute;
    top: $spacing-base;
    right: $spacing-base;
    width: 26px;
    height: 26px;
    path {
      fill: green;
    }
  }
}
