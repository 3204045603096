.c-admin-table {
  border: solid 1px rgba(0, 0, 0, 0.1);

  &-header {
    padding: 20px 10px;
    color: $font-color;
    background-color: $table-bg;

    &.-bold {
      font-weight: $fw-semi-bold;
    }
  }

  &-row {
    padding: 20px 10px;
    background: #fff;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    @include gpuAccelerate();
    @include transition-this(all, 0.1s, ease-in-out);

    &:hover {
      background-color: #eee;
    }
  }

  &-footer {
    padding: 20px 10px;
    color: $font-color;
    background: $table-bg;
    border-top: solid 1px rgba(0, 0, 0, 0.1);

    &.-bold {
      font-weight: $fw-semi-bold;
    }
  }
}
