$modal-bg-color: #393939;
$sidebar-bg-color: #15171b;
$table-header-bg-color: #15171b;
$table-row-bg-color: #393939;
$table-row-hover-bg-color: #4c4c4c;
$panel-bg-color: #1b1d21;
$form-bg-color: #1b1d21;
$font-color: #fff;
$primary-color: #3daf55;
$field-bg-color: #393939;
$field-label-color: #c2c2c2;

.c-admin-app {
  &.-add {
    color: $font-color;
    background-image: linear-gradient(
        180deg,
        rgba(29, 29, 30, 0.97),
        rgba(29, 29, 30, 0.97)
      ),
      url("../../public/images/backgrounds/adde_bg_image@3x.png");
    background-repeat: no-repeat;
    background-position: 50% 100%;

    .c-modal {
      background-color: $modal-bg-color;
    }

    .c-sidebar {
      background-color: $sidebar-bg-color;
    }

    .c-loading {
      background-color: $modal-bg-color;
    }

    .c-admin-menu-item-link {
      color: $font-color;
    }

    .c-admin-panel {
      background-color: $panel-bg-color;
      border: solid 1px $panel-bg-color;

      &-title {
        color: $primary-color;
      }

      .c-admin-panel {
        background-color: $table-row-bg-color;
        border: solid 1px $table-row-bg-color;
      }
    }

    .c-admin-form {
      background-color: $form-bg-color;
    }

    .c-admin-table {
      border: solid 1px $table-header-bg-color;

      &-header {
        color: $font-color;
        background-color: $table-header-bg-color;
      }

      &-row {
        color: $font-color;
        background-color: $table-row-bg-color;

        &:hover {
          background-color: $table-row-hover-bg-color;
        }
      }

      &-footer {
        color: $font-color;
        background-color: $table-header-bg-color;
      }
    }

    #{".c-button"},
    a#{".c-button"},
    a #{".c-button"} {
      color: #000;
      background-color: $primary-color;
      border: 2px solid $primary-color;

      &:hover {
        color: $font-color;
        background-color: $primary-color;
        border: 2px solid $primary-color;
      }

      &.-secondary {
        color: $font-color;
        background-color: transparent;
        border-color: $font-color;

        &:hover {
          color: #000;
          background-color: $font-color;
        }
      }
    }

    .c-accordion-indicator {
      &::before,
      &::after {
        background: $font-color;
      }
    }

    .c-info-expander-button {
      color: $font-color;
    }

    .c-task-status {
      color: $primary-color;
    }

    .c-checkbox-visual-check {
      border-color: $font-color;
    }

    .c-circle-buttons {
      background: $primary-color;
    }

    .c-environment-title {
      color: $primary-color;
    }

    .c-link {
      color: $primary-color;
    }

    .c-textarea-field,
    .c-input-field {
      color: $font-color;
      background-color: $field-bg-color;
      border-bottom: 1px solid $font-color;
    }

    .c-input-label {
      color: $field-label-color;
    }

    .c-select-input {
      &-group {
        background-color: $field-bg-color;
      }

      &-input-label {
        color: $field-label-color;
      }

      &-field {
        color: $font-color;
      }

      &-arrow {
        svg {
          fill: $font-color;
        }
      }
    }

    .c-combo-field .c-input .c-input-group .c-input-field {
      color: $font-color;
      background-color: $field-bg-color;
    }

    .c-combo-field-result {
      background-color: $field-bg-color;

      li {
        &.-active,
        &:hover {
          background-color: #4e4e4e;
        }
      }
    }

    .c-password-input-icon {
      svg {
        fill: $font-color;
      }
    }

    .c-file-input-label,
    .c-file-input-name {
      color: $font-color;
    }

    .c-switch {
      background-color: #aaa;
      border-color: #aaa;

      &-ball {
        background-color: $field-bg-color;
        border-color: $field-bg-color;
      }

      &.-active {
        background-color: $field-bg-color;
        border-color: $field-bg-color;

        .c-switch-ball {
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }
    }

    .c-date-picker {
      input {
        color: $font-color;
        background-color: $field-bg-color;
        border-bottom: 1px solid $font-color;
      }
    }

    .vdatetime {
      input[type="text"] {
        color: $font-color;
        background-color: $field-bg-color;
        border-bottom: 1px solid $font-color;
      }

      &-clear {
        svg {
          fill: $font-color;
        }
      }
    }
  }
}
