.c-password-input {
  position: relative;
  width: 100%;
  max-width: 400px;

  .c-input {
    width: 90%;
    max-width: 360px;
  }

  &-toggle {
    position: absolute;
    top: 11px;
    right: 12px;
    z-index: 30;

    &:hover {
      cursor: pointer;
    }
  }

  &-icon {
    width: 20px;

    svg {
      fill: #2c2c31;
    }
  }
}
