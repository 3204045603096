.c-cd-container {
  padding-left: $menu-width;
  @include media-breakpoint-down("sm") {
    padding-left: 0;
  }

  &.-stats-bar {
    padding-top: 64px;
  }
}
