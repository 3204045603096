.c-notifications {
  max-width: 350px;
  padding: 100px 0 20px;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    max-width: 350px;
    padding: 40px 0 20px;
    margin: 0 0 0 65px;
  }
  @include media-breakpoint-down(sm) {
    padding: 0 15px 20px;
    margin: 0 auto;
  }

  &-title {
    margin: 0 0 40px;
    font-size: 30px;
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }

    strong {
      @include media-breakpoint-down(sm) {
        font-weight: 600;
      }
    }
  }
}
