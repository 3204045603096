$class: ".c-amount-input";

#{$class} {
  display: inline-block;
  width: 100%;
  max-width: 220px;
  margin: 0 0 40px;

  &.-no-steps {
    max-width: 200px;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: $input-min-height;
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 6px;
    transition: all 0.3s ease;

    &.-error {
      border-color: $color-error;
    }
  }

  &-unit {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
    height: $input-min-height;
    font-size: 20px;
    color: #fff;
    background: #000;
    border-radius: $input-radius 0 0 $input-radius;
  }

  .c-input-label {
    top: 4px;
    left: 20px;
    width: 100%;
  }

  &-input {
    width: 100%;
    min-height: $input-min-height;
    padding: 0 25px 0 15px;
    font-size: 20px;
    text-align: left;
    background: transparent;
    border: 0;
    border-radius: 0 $input-radius $input-radius 0;

    &::placeholder {
      font-weight: $fw-light;
    }

    &:focus {
      background-color: $input-color-background-focused;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 0;
    line-height: 1;
    // background-color: green;
    color: #000;
    transition: all 0.3s ease;

    &:hover {
      cursor: pointer;
    }

    &.-disabled {
      opacity: 0.1;

      &:hover {
        cursor: not-allowed;
      }
    }

    &.-plus {
      #{$class}-button {
        &-item {
          border-right: 0;
          border-left: 1px solid rgba(#707070, 0.07);
        }
      }
    }

    &-item {
      padding: 4px 15px;
      font-size: 18px;
      border-right: 1px solid rgba(#707070, 0.07);
    }
  }
}
