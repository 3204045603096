.c-gdpr {
  &-label {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  .c-checkbox-container {
    margin: 10px 0 30px;
  }

  &-content,
  .c-checkbox-content {
    font-size: 12px;
  }

  .c-input-error.-standalone {
    margin-top: -20px;
  }
}
