$button-bg: $color-brand-primary !default;
$button-transition: all 0.5s;

%a-button-disabled {
  color: $button-color-text;
  cursor: not-allowed;
  background-color: $button-bg;
  opacity: 0.5;
}

$class: ".c-button";

#{$class},
a#{$class},
a #{$class} {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: $button-width;
  min-height: $button-height;
  padding: $button-padding;
  margin-bottom: 5px;
  overflow: hidden;
  font-family: $font-main;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  line-height: 1;
  color: $button-color-text;
  text-align: center;
  text-transform: $button-transform;
  vertical-align: middle;
  cursor: pointer;
  background-color: $button-bg;
  border: 2px solid $button-bg;
  border-radius: $button-radius;
  outline: none;
  box-shadow: $button-shadow;
  transition: $button-transition;

  &.-tiny {
    min-width: unset;
  }

  &:focus {
    outline: none;
  }

  &-text {
    position: relative;
  }

  &-loader {
    position: absolute;
    width: 24px;
    stroke: $button-color-text;
  }

  &.-arrow-nav {
    @include gpuAccelerate();
    @include transition-this(all, 0.2s, $ease-back);

    padding: 0;
    font-size: 18px;
    line-height: 0;
    pointer-events: all;

    &.-disabled {
      @include opacity(0);
    }
  }

  &:not(.-secondary):not(.-invert):not(:disabled) {
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &-arrow {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 18px;
    height: 18px;
    transition: all 200ms ease-in-out;
    transform: rotate(90deg);

    path {
      fill: $font-color;
      transition: $button-transition;
    }

    &.-arrow-graphic {
      position: absolute;
      right: 0;
      left: 0;
      width: 15px;
      height: 15px;
      margin: auto;
      opacity: 1;
      transform: rotate(-90deg);

      path {
        fill: #000;
      }
    }
  }

  &:hover {
    text-decoration: none;

    #{$class}-arrow {
      &.-arrow-graphic {
        right: 0;
        fill: #fff;
        transform: rotate(-90deg) scale(1.5);
      }
    }
  }

  &:disabled {
    @extend %a-button-disabled;

    &:hover {
      @extend %a-button-disabled;
    }
  }

  // Helper classes

  &.-loading {
    pointer-events: none;
    cursor: wait;
    opacity: 0.3;

    #{$class}-text {
      display: none;
    }
  }

  &.-secondary {
    color: $font-color;
    background-color: transparent;
    border-color: $color-brand-secondary;
    @include media-breakpoint-down("sm") {
      &.-back {
        margin-left: 10px;

        #{$class}-arrow {
          &.-arrow-graphic {
            transform: rotate(-180deg) scale(1.5);
          }
        }
      }

      &.-next {
        margin-right: 10px;
        color: #fff;
        background-color: $color-brand-primary;
        border-color: $color-brand-primary;

        #{$class}-arrow {
          right: 10px;
          opacity: 1;

          &.-arrow-graphic {
            right: 0;
            fill: #fff;
            transform: rotate(0deg) scale(1.5);
          }
        }
      }
    }

    &.-fill {
      background: #fff;
    }

    &.-primary-text {
      color: $button-bg;
    }

    &:hover {
      color: #fff;
      background-color: $color-brand-secondary;
      border-color: $color-brand-secondary;

      &:disabled {
        border-color: $font-color;

        &,
        &:hover {
          @extend %a-button-disabled;

          color: $font-color;
          background: transparent;
          border-color: $color-brand-secondary;
        }
      }
    }

    &.-invert {
      color: #fff;
      background-color: transparent;
      border-color: #fff;

      .-arrow-graphic path {
        fill: #fff;
      }

      &:hover {
        color: $font-color;
        background: $color-brand-primary;
        border-color: $color-brand-primary;
      }
    }
  }

  // Helper classes - sizes
  &.-xs {
    min-width: initial;
    min-height: 22px;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: $button-font-weight;
  }

  &.-sidebar {
    min-width: 60px;
    min-height: 26px;
    padding: 10px 14px;
    font-size: 12px;
    font-weight: 500;
  }

  &.-small,
  &.-sm {
    min-width: 120px;
    min-height: 34px;
    padding: 6px 14px;
    font-size: 16px;
    font-weight: $button-font-weight;
  }

  &.-large,
  &.-lg {
    @include media-breakpoint-up(md) {
      min-width: 240px;
      min-height: 60px;
      padding: 16px 36px;
      font-size: 22px;
    }
  }

  &.-full {
    width: 100%;
  }

  &.-wide {
    min-width: 220px;
  }

  &.-xwide {
    min-width: 250px;
  }

  // modifiers

  &-fixed {
    position: fixed;
    bottom: 30px;
    left: calc(50% - 90px);
    z-index: 98;
    width: 180px;
  }

  &.-round {
    width: 50px;
    min-width: initial;
    height: 50px;
    min-height: initial;
    border-radius: 50%;

    &:hover {
      background-color: $color-brand-primary;
      border-color: $color-brand-primary !important;
    }
  }

  &.-margin-auto {
    margin: 0 auto 0.6em;
  }

  &.-vspace {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &.-caps {
    text-transform: uppercase;
  }

  &.-bold {
    font-weight: bold;
  }

  &.-no-outline {
    outline: 0 !important;
  }

  &.-auto {
    min-width: initial;
  }

  @media only print {
    display: none !important;
  }
}
