%a-tile {
  padding: 20px 10px;
  background: #fff;
  border: 2px solid rgba($color-brand-primary, 0);
  border-radius: 6px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.16);
  transition: all 200ms ease-in-out;

  &.-active {
    border-color: rgba($color-brand-primary, 1);
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0);
  }

  &:hover {
    border-color: rgba($color-brand-primary, 0.5);
  }

  &:focus {
    outline: none;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
  }
}
