// This file contains mixins styles for the scss components

@mixin gpuAccelerate($property: all, $noTranslate: "false") {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  will-change: #{$property};

  @if $noTranslate == "false" {
    transform: translateZ(0);
    transform: translate3d(0, 0, 0);
  }
}

@mixin transition-this($type: all, $speed: 0.3s, $ease: linear) {
  -webkit-transition: $type $speed $ease;
  -moz-transition: $type $speed $ease;
  -ms-transition: $type $speed $ease;
  -o-transition: $type $speed $ease;
  transition: $type $speed $ease;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin opacity($float) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=($float*100))";
  filter: alpha(opacity=($float * 100));
  -moz-opacity: $float;
  -khtml-opacity: $float;
  opacity: $float;
}

@mixin translateX($amount: -50%) {
  // will-change: transform;
  -moz-transform: translateX($amount);
  -webkit-transform: translateX($amount);
  -o-transform: translateX($amount);
  -ms-transform: translateX($amount);
  transform: translateX($amount);
}

@mixin translateY($amount: -50%) {
  // will-change: transform;
  -moz-transform: translateY($amount);
  -webkit-transform: translateY($amount);
  -o-transform: translateY($amount);
  -ms-transform: translateY($amount);
  transform: translateY($amount);
}
