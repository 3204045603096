.c-document-upload {
  padding: $spacing-base;
  &-icon {
    width: 100px;
  }
  &-filename {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    height: 30px;
    padding: 20px;
    margin: 0 auto;
    color: white;
    cursor: pointer;
    background-color: green;
    border-radius: 5px;
  }
  &-close-icon {
    width: 14px;
    margin-left: 20px;
  }
  &-success {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
    }
  }
  &-error {
    padding: calc($spacing-base / 2);
    margin-bottom: $spacing-base;
    color: white;
    cursor: pointer;
    background-color: red;
    border-radius: 5px;
  }
  &-drop-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    padding: $spacing-base;
    margin: 0 auto;
    cursor: pointer;
    border: 4px dashed rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    &.-dragging {
      border-color: green;
    }
    svg {
      width: 100px;
      opacity: 0.3;
    }
  }
  input[type="file"] {
    transform: translateX(-9999px);
  }
}
