.c-dob-individual {
  position: relative;
  width: 100%;
  max-width: 260px;
  margin: 0 0 28px;

  &-label {
    margin: 0 0 5px;
    font-size: 12px;
    font-weight: 300;
    color: #1b1b1b;
    text-transform: uppercase;
  }

  .c-input {
    width: 100%;
    max-width: 60px;
    margin: 0;

    &.t-dd_start_date_year {
      max-width: 96px;
    }
  }

  .t-dob_year,
  .t-account_dob_year {
    width: 100%;
    max-width: 68px;
  }

  .c-input-icon {
    top: initial;
    bottom: 11px;
  }
}
