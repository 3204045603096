.c-notifications-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  max-height: 100%;
  font-size: 16px;
  text-align: center;
  background: transparent;

  &.-show {
    background: rgba(224, 224, 224, 0.25);

    .c-notifications-box {
      width: 100%;
    }
  }

  .c-notifications-box {
    width: 500px;
    max-height: 100%;
    padding: 20px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid rgba(138, 138, 138, 0.1);
    border-radius: 6px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

    &.-extended {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }

      &-close-box {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        &-icon {
          font-size: 19px;
        }
      }

      .c-button {
        margin-top: 10px;
      }
    }
  }
}
