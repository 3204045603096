.c-combo-field {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-width: 360px;

  &-result {
    position: absolute;
    top: 45px;
    z-index: 100;
    display: none;
    width: 100%;
    max-height: 300px;
    padding: 0;
    overflow-y: scroll;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 3px 20px #00000029;

    &.-show {
      display: block;
    }

    li {
      padding: 0 ($spacing-base);
      line-height: 2.5rem;
      cursor: pointer;
      border-bottom: 1px solid #ccc;

      &:first-child {
        border-radius: 5px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border: 0;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &.-active,
      &:hover {
        background-color: #ccc;
      }
    }
  }

  &-hitbox {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 44px;
    cursor: pointer;
    background: black;
    border-radius: 0 3px 3px 0;

    @include media-breakpoint-down("sm") {
      right: 5px;
    }

    &::after {
      position: absolute;
      top: calc(50% - 14px);
      right: 22px;
      width: 15px;
      height: 30px;
      pointer-events: none;
      cursor: pointer;
      content: url("../../public/images/icons/arrow-white.svg");
      transform: rotate(90deg);
    }
  }

  .c-input {
    width: 100%;
    max-width: inherit;

    .c-input-group {
      height: 44px;

      .c-input-field {
        padding-right: 75px;
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 6px;
      }

      .c-input-icon {
        right: 52px;
      }
    }
  }
}
