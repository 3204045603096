.c-note-comments {
  &-list {
    margin-top: 20px;
  }

  &-date {
    margin: 0;
    font-weight: 500;
    color: #000;
  }

  &-text {
    margin-top: 20px;
  }

  &-author {
    margin-top: 0;
  }
}
