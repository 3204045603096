.c-circle-buttons {
  display: block;
  width: 200px;
  height: 200px;
  padding: 70px 30px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: $fw-bold;
  color: $button-color-text;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background: $button-bg;
  border-radius: 100px;
  outline: none;
  box-shadow: $button-shadow;

  @include media-breakpoint-down(sm) {
    width: 150px;
    height: 150px;
    padding: 50px 20px;
  }

  &:hover {
    color: #fff;
    background-color: $color-brand-primary;
  }

  &:not([href]) {
    color: $button-color-text;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }

  svg {
    display: block;
    margin: 0 auto;
    font-size: 30px;
  }
}
