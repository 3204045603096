.c-create-task {
  position: relative;
  height: inherit;

  .vc-text-base {
    width: 100%;
    padding: 16px 30px 5px 10px;
    font-size: 14px;
    font-weight: 400;
    background: #f5f5f5;
    border: 0;
    border-bottom: 1px solid rgba(44, 44, 60, 0.6);
    border-radius: 4px 4px 0 0;
    transition: all 0.2ms ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
