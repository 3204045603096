.c-notifications-item {
  position: relative;
  width: 100%;
  max-width: 320px;
  padding: 20px;
  margin: 0 0 20px;
  font-size: 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 16px #00000010;

  &:first-child {
    color: #fff;
    background-color: #1bbbc1;

    &::after {
      position: absolute;
      top: -12px;
      left: 40px;
      content: " ";
      border-top: 0;
      border-right: 10px solid transparent;
      border-bottom: 12px solid #1bbbc1;
      border-left: 10px solid transparent;
    }
  }

  &:last-child {
    margin: 0;
  }

  &-date {
    font-size: 16px;
    font-weight: 600;
  }
}
