.c-admin-alert {
  &-text {
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;

    &.-error {
      color: $color-error;
    }

    &.-success {
      color: $color-success;
    }
  }
}
