.c-input {
  @extend %a-input-container;

  position: relative;
  display: inline-block;

  &.-full-width {
    max-width: 100%;
  }

  &.-small-width {
    max-width: 140px;
  }

  &.-medium-width {
    max-width: 200px;
  }

  &.-align-left {
    input[type="text"] {
      padding: 22px 20px 12px 0;
    }
  }

  &.-hour {
    width: 60px;
    margin: 0;
    margin-right: 5px;

    input[type="number"] {
      padding: 16px 5px 5px 10px;
    }
  }

  &.-min {
    width: 60px;
    margin: 0;
    margin-left: 5px;

    input[type="number"] {
      padding: 16px 5px 5px 10px;
    }
  }

  &.-disabled {
    .c-input-icon {
      display: none;
    }

    .c-input-label {
      left: 0;
      font-weight: 400;
      opacity: 0.5;
    }

    .c-input-field {
      padding: 16px 0 5px;
      border-color: rgb(226, 226, 226);
    }
  }

  &.-align-center {
    margin: 0 auto;

    .c-input-group {
      display: flex;
      justify-content: center;

      .c-input-label {
        left: initial;
        padding: 22px 20px 12px 22px;
      }

      input {
        padding: 12px;
        padding-top: 22px;
        text-align: center;
      }
    }
  }

  &.-align-right {
    input[type="text"] {
      padding: 22px 0 12px 20px;
      text-align: right;
    }

    .c-input-label {
      right: 0;
      left: initial;
      padding: 22px 0 12px 20px;
    }

    .-success,
    .-error {
      right: auto;
      left: 0;
    }
  }

  &.-align-center-sm {
    @include media-breakpoint-down("sm") {
      margin: 0 auto;

      .c-input-group {
        display: flex;
        justify-content: center;

        .c-input-label {
          left: initial;
          padding: 22px 20px 12px 22px;
        }

        input {
          padding: 12px;
          padding-top: 22px;
          text-align: center;
        }
      }
    }
  }

  // to be replaced with &.-align-center above
  &.-center {
    margin: 0 auto;

    .c-input-group {
      display: flex;
      justify-content: center;

      .c-input-label {
        left: initial;
        padding: 22px 20px 12px 22px;
      }

      input {
        padding: 12px;
        padding-top: 22px;
        text-align: center;
      }
    }
  }

  &.-dark-placeholder {
    .c-input-label {
      color: darken($input-color-placeholder, 30%);
    }
  }

  &.-opaque {
    .c-input-field {
      background: #fff;
    }
  }

  &.-boxed {
    .c-input-field {
      padding: 20px 20px 10px 10px;
      font-size: 18px;
      text-shadow: none;
      background: #fff;
      border: 1px solid $font-color;
    }

    .c-input-label {
      // padding: 18px 0 10px 10px;

      &.-focus {
        margin-top: -3px;
        margin-left: 0;
      }
    }

    .c-input-icon {
      top: calc(100% - 42px);
      right: 15px;
    }
  }

  &.t-searchTerm {
    max-width: 280px;
  }

  &-field {
    @extend %a-input;

    width: 100%;
    padding: $input-padding;
    appearance: none;

    &.-highlight {
      border-color: $color-brand-primary;
    }

    &.-value,
    &:focus {
      outline: none;
    }

    &.-icon {
      padding-right: 40px;
    }

    &.-uppercase {
      text-transform: uppercase;
    }

    &:disabled {
      background-color: transparent;

      &:hover {
        cursor: not-allowed;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: none;
      transition: background-color 5000s ease-in-out 0s;
    }

    &::-webkit-input-placeholder {
      // color:#999;
      font-weight: $fw-light;
      color: $input-color-placeholder;
    }
  }

  &-group {
    position: relative;
  }

  &-label {
    position: absolute;
    top: 10px;
    left: 11px;
    margin: 0;
    font-family: $font-main;
    font-size: $font-size;
    font-weight: $input-font-weight;
    color: $input-color-placeholder;
    pointer-events: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.-focus {
      top: 5px;
      left: 11px;
      font-size: 11px;
    }
  }

  &-instructions,
  &-error {
    font-size: 12.5px;
    text-align: left;
  }

  &-instructions {
    padding-left: 10px;
  }

  &-error {
    font-size: $font-size;
    color: $color-error;

    &.-center {
      text-align: center;
    }
  }

  &-icon {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 20px;
    pointer-events: none;

    img {
      width: 100%;
    }
  }
}
