.c-radioselect-box {
  &-item {
    @extend %a-tile;

    padding: 20px;
    margin-bottom: 20px;
    text-align: left;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
  }
}
