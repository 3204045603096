$square-size: 24px;
$increase: 10px;

$class: ".c-checkbox";

#{$class} {
  margin: 0 0 20px;
  cursor: pointer;

  &.-orb {
    #{$class}-visual-check {
      border-radius: 50%;
    }
  }

  &:hover {
    #{$class}-visual-check {
      width: calc(#{$square-size} + #{$increase});
      height: calc(#{$square-size} + #{$increase});
      border-color: rgba($color-brand-primary, 0.5);

      svg {
        opacity: 0.5;
        fill: $color-brand-primary;
        transform: scale(1);
      }
    }
  }

  &-visual {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(#{$square-size} + #{$increase});
    height: calc(#{$square-size} + #{$increase});
    margin: 0 10px 0 0;

    &.-active {
      #{$class}-visual-check {
        width: calc(#{$square-size} + #{$increase});
        height: calc(#{$square-size} + #{$increase});
        border-color: rgba($color-brand-primary, 1);

        svg {
          opacity: 1;
          fill: $color-brand-primary;
          transform: scale(1);
        }
      }
    }

    &-check {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $square-size;
      height: $square-size;
      border: 2px solid $font-color;
      transition: all 0.15s ease-in-out;

      svg {
        width: 18px;
        opacity: 0;
        transition: all 0.15s ease-in-out;
        transform: scale(0);
      }
    }
  }

  // white version
  .-invert & {
    .c-checkbox-visual {
      &-check {
        border-color: #fff;

        svg {
          fill: #fff;
        }
      }

      &.-active {
        .c-checkbox-visual-check {
          svg {
            opacity: 1;
          }
        }
      }
    }

    &:hover {
      .c-checkbox-visual-check {
        svg {
          opacity: 0.65;
        }
      }
    }
  }

  &-content {
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
  }
}
