.c-email-client {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;

  &-topbar {
    height: 100px;
  }

  &-menu {
    max-width: 190px;
  }

  &-menu-block {
    ul {
      @include gpuAccelerate();
      @include transition-this(all, 0.3s, ease-in-out);

      max-height: 0;
      overflow: hidden;

      &.-open {
        max-height: 500px;
      }
    }
  }

  &-header {
    cursor: pointer;
  }

  &-ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      a {
        display: inline-block;
        width: 100%;
        padding: 10px 0;
        background-color: #fff;
        border-top: 1px solid lighten(#000, 80%);
      }

      &:last-child {
        a {
          border-bottom: 1px solid lighten(#000, 80%);
        }
      }
    }
  }
}
