.c-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  max-width: 95%;
  max-height: 95%;
  padding: 40px 30px 20px;
  margin: auto;
  overflow: visible;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 6px;
  outline: none;
  box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.2);

  @include media-breakpoint-down(sm) {
    width: 97%;
    max-width: 97%;
    height: 97%;
    max-height: 97%;
    padding: 30px 10px 10px;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.5);
  }

  @include media-breakpoint-up(md) {
    &.-square {
      width: 600px;

      &:not(.-auto-height) {
        height: 500px;
      }
    }

    &.-narrow {
      width: 500px;
      padding: 0 30px;
    }

    &.-large {
      height: 600px;
    }

    &.-xl {
      height: 800px;
    }

    &.-auto {
      height: auto;
    }
  }

  &:focus {
    outline: none;
  }

  &.-open {
    // top:0vh;
  }

  &.-full {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    // display:none;
    // &.-open {
    //   display:block;
    // }
  }

  &-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    // background: rgba(0,0,0, 0.6);
    backdrop-filter: blur(15px);
  }

  &-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-content {
    height: 100%;
    max-height: 100%;
    overflow: auto;

    &.-auto-height {
      max-height: initial;
      overflow: unset;
    }

    &.-overflow-visible {
      overflow: visible;
    }
  }

  &-toggle {
    display: inline-block;
    margin: auto;
  }

  &-close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 2;
    width: 40px;
    height: 40px;
    text-align: center;
    background: #fff;
    border: 2px solid $font-color;
    border-radius: 50%;
    @include media-breakpoint-down(sm) {
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
    }

    &,
    &-icon {
      transition: all 0.2s ease-in-out;
    }

    &-icon {
      position: absolute;
      top: calc(50% - 5px);
      left: calc(50% - 6px);
    }

    &:hover {
      color: #fff;
      background: $font-color;

      .c-modal-close-icon path {
        fill: #fff;
      }
    }
  }

  &-scroll-fade {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    // display: none;
    z-index: 1;
    width: 100%;
    height: 30px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );

    &.-bottom {
      top: initial;
      bottom: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}
