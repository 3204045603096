.c-sidebar-item {
  width: 100%;
  padding: 15px 0;
  margin: 0 auto;
  font-size: 13px;
  line-height: 1.1;
  letter-spacing: 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.23137254901960785);

  &:last-child {
    nav & {
      border-bottom: 0;
    }
  }

  &-icon {
    display: block;
    width: 20px;
    margin: 0 auto 5px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
  }

  &,
  path,
  rect,
  circle {
    color: #fff;
    fill: #fff;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: $color-brand-primary;
    outline: none;

    svg {
      path,
      rect,
      circle {
        fill: $color-brand-primary;
      }
    }
  }

  &:disabled {
    opacity: 0.1;
  }

  &:focus,
  &.-active {
    color: $color-brand-primary;
    outline: none;

    svg {
      path,
      rect,
      circle {
        fill: $color-brand-primary;
      }
    }
  }
}
