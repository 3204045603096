.toasted-container {
  .toasted {
    display: block;
    text-align: center;

    &.outline {
      padding: 20px;
      font-size: 20px;
      font-weight: bold;
    }

    .svg-inline--fa {
      margin-right: 20px;

      &.after {
        margin-right: 0;
        margin-left: 20px;
      }
    }

    .action {
      padding: $button-padding;
      margin: 10px;
      color: $button-color-text;
      border-radius: $button-radius;

      .svg-inline--fa {
        font-size: 20px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
