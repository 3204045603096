.vdatetime {
  display: inline-block;
  max-width: 140px;

  input[type="text"] {
    width: 100%;
    padding: 16px 30px 5px 10px;
    font-size: 14px;
    font-weight: 400;
    background: #f5f5f5;
    border: 0;
    border-bottom: 1px solid rgba(44, 44, 60, 0.6);
    border-radius: 4px 4px 0 0;
    -webkit-transition: all 0.2ms ease-in-out;
    transition: all 0.2ms ease-in-out;
    -webkit-appearance: none;
  }
}

.vdatetime-clear {
  position: absolute;
  top: 10px;
  left: calc(175px - 15px);
  z-index: 10;
  width: 20px;
  height: 20px;
  cursor: pointer;

  img,
  svg {
    width: inherit;
    height: inherit;
  }
}
